body{
    margin: 0;
    padding: 0;
    font-family: montserrat;
}
*,::after,::before{
    transition:background-color 1s, color 1s;
}
.container {
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}
footer{
    margin-top: 20px;
    padding: 10px;
}